import React from "react"
import { CATEGORIES } from "../../constants/categories"
import { INDUSTRIES } from "../../constants/industries"
import Badges from "../badges"

const badgeMap = { ...CATEGORIES, ...INDUSTRIES }
const BlogPostEntry = ({ post }) => {
  const { excerpt, date, link, title, author, categories, tags, externalArticleLink } = post
  const badgeList = [...categories.nodes.map((category) => category.name), ...tags.nodes.map((tag) => tag.name)]

  return (
    <article className="blog-listing__blog-post">
      <span>{date}</span>
      <div className="blog-listing__blog-post__badges">
        <Badges badgeList={badgeList} badgeMap={badgeMap} />
      </div>
      <h3>{title}</h3>
      {/*<h4>By {author.node.name}</h4>*/}
      <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>
      {externalArticleLink?.linkToArticle ? (
        <a href={externalArticleLink.linkToArticle} className="button button--external button--primary" aria-label={"Read more about " + title} target="_blank">
          Read More
        </a>
      ) : (
        <a href={link} className="button button--primary" aria-label={"Read more about " + title}>
          Read More
        </a>
      )}
    </article>
  )
}

export default BlogPostEntry
