import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/Layout"
import Pagination from "../../components/Pagination"
import BlogPostEntry from "../../components/blog/BlogPostEntry"
import BlogHeader from "../../components/blog/BlogHeader"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import BlogCarousel from "../../components/blog/BlogCarousel"
import ProfileCallOut from "../../components/ProfileCallOut"
import { INDUSTRIES_LIST } from "../../constants/industries"
import { CATEGORIES_LIST } from "../../constants/categories"

const Blog = ({ pageContext }) => {
  const { allWpPost } = useStaticQuery(graphql`
    query PostQuery {
      allWpPost {
        edges {
          node {
            id
            link
            title
            date(formatString: "MM DD YYYY")
            excerpt
            author {
              node {
                name
              }
            }
            tags {
              nodes {
                name
              }
            }
            featuredImage {
              node {
                altText
                sourceUrl
              }
            }
            categories {
              nodes {
                name
              }
            }
            externalArticleLink {
              linkToArticle
            }
          }
        }
      }
    }
  `)
  const posts = allWpPost.edges

  const groupedPosts = posts.reduce(
    (acc, curr) => {
      if (curr.node.tags.nodes.find((tag) => tag.name.toUpperCase() === "FEATURED")) {
        return {
          ...acc,
          featuredPosts: [...acc.featuredPosts, curr],
        }
      } else {
        return {
          ...acc,
          otherPosts: [...acc.otherPosts, curr],
        }
      }
    },
    { featuredPosts: [], otherPosts: [] }
  )

  const {
    breadcrumb: { crumbs },
  } = pageContext

  const INDUSTRY_FILTER_OPTIONS = {
    label: "Industry",
    options: INDUSTRIES_LIST,
  }
  const CATEGORY_FILTER_OPTIONS = {
    label: "Category",
    options: CATEGORIES_LIST,
  }
  const [selectedFilters, setSelectedFilters] = useState([])

  const toggleFilter = (filter) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((item) => item !== filter))
    } else {
      setSelectedFilters([...selectedFilters, filter])
    }
  }
  const renderFilters = ({ label, options }) => {
    return (
      <div className="search-filters--types industry">
        <h4>{label}</h4>
        <div className="tags--container">
          {options.map((item, index) => {
            console.log(selectedFilters.includes(item.value))
            return (
              <label htmlFor={`${label}-${item.value}`} key={`${label}-${item.value}`} className={`${item.categoryClass} ${selectedFilters.includes(item.value) ? "checked" : ""}`} onChange={() => toggleFilter(item.value)}>
                <input type="checkbox" name={item.label} id={`${label}-${item.value}`} />
                {item.label}
              </label>
            )
          })}
        </div>
      </div>
    )
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(5)
  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const paginationPosts = groupedPosts.otherPosts
    .filter((post) => {
      if (selectedFilters.length === 0) return true
      const node = post.node
      return node.categories.nodes.find((category) => selectedFilters.map((f) => f.toUpperCase()).includes(category.name.toUpperCase())) || node.tags.nodes.find((tag) => selectedFilters.map((f) => f.toUpperCase()).includes(tag.name.toUpperCase()))
    })
    .slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage)

  return (
    <Layout>
      <Breadcrumb crumbs={crumbs} crumbSeparator=" / " crumbLabel={"Blog"} />
      <BlogHeader />
      <section className="blog-listing">
        <h1>What's Brewing in MKE Tech</h1>
        {groupedPosts.featuredPosts.length ? <BlogCarousel blogPosts={groupedPosts.featuredPosts.map((post) => post.node)} /> : null}
        <div className="search-filters always-open">
          <div className="search-filters--container">
            {renderFilters(INDUSTRY_FILTER_OPTIONS)}
            {renderFilters(CATEGORY_FILTER_OPTIONS)}
          </div>
        </div>

        <div className="pagination-container">
          <div className="paginate">
            <Pagination postsPerPage={postsPerPage} totalPosts={paginationPosts.length} paginate={paginate} />
          </div>
          <div className="pageinate-page">
            Displaying{" "}
            <div className="form-field dropdown ">
              <div className="select-container">
                <div className="select-container__select">
                  <select
                    onChange={(e) => {
                      setPostsPerPage(e.currentTarget.value)
                      setCurrentPage(1)
                    }}
                  >
                    <option>5</option>
                    <option>10</option>
                    <option>100</option>
                  </select>
                </div>
              </div>
            </div>
            of {paginationPosts.length} results
          </div>
        </div>
        {paginationPosts.map((post, index) => {
          return (
            <React.Fragment>
              <BlogPostEntry post={post.node} key={post.node.id} />
              {index % 3 === 0 ? <ProfileCallOut /> : null}
            </React.Fragment>
          )
        })}
        <div className="pagination-container">
          <div className="paginate">
            <Pagination postsPerPage={postsPerPage} totalPosts={paginationPosts.length} paginate={paginate} />
          </div>
          <div className="pageinate-page">
            Displaying{" "}
            <div className="form-field dropdown ">
              <div className="select-container">
                <div className="select-container__select">
                  <select
                    onChange={(e) => {
                      setPostsPerPage(e.currentTarget.value)
                      setCurrentPage(1)
                    }}
                  >
                    <option>5</option>
                    <option>25</option>
                    <option>100</option>
                  </select>
                </div>
              </div>
            </div>
            of {paginationPosts.length} results
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Blog
